@use "ds";

.agentsSearchDistrictModalFooter {
  display: flex;
  column-gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    justify-content: flex-end;
  }

  button {
    flex: 1;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      flex: 0;
    }
  }
}
