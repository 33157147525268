@use "ds";
@use "tokens";
@use "typography";

.agentsListHeader {
  background-color: tokens.$color_bg_surface_primary;
  border-top-left-radius: tokens.$radius_xlarge;
  border-top-right-radius: tokens.$radius_xlarge;
}

.header {
  padding: 24px 16px 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 24px;
    justify-content: space-between;
    padding: 24px 24px 16px;
  }

  .headerTexts {
    display: grid;
    row-gap: 8px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .headerDescription {
    color: tokens.$color_text_secondary;
    display: inline-block;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_xsmall;

      color: tokens.$color-text_primary;
    }
  }

  .headerSubtitle {
    display: none;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      color: tokens.$color_text_secondary;
      display: block;
      width: 100%;
    }
  }

  .headerSort {
    display: none;
    width: 100%;
    max-width: 257px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: block;
    }
  }
}

.headerTags {
  display: flex;
  gap: 8px;
  padding: 0 16px;
}
