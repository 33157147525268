@use "ds";
@use "tokens";
@use "typography";

.agencyReviewsTitle {
  display: grid;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 12px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      align-items: center;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      justify-content: flex-start;
      padding: 0 24px;
    }
  }

  .title {
    color: tokens.$color_text_primary;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;
    }
  }

  .rating {
    display: flex;
    align-items: center;
    column-gap: 4px;
    height: 24px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      height: 28px;
    }
  }

  .totalRating {
    display: none;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: block;
      color: tokens.$color_text_secondary;
      padding-top: 2px;
    }
  }
}
