@use "ds";
@use "tokens";
@use "typography";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  row-gap: 8px;

  .headerDescription {
    color: tokens.$color_text_secondary;
    background-color: tokens.$color_bg_surface_primary;
    padding: 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_xsmall;

      padding: 24px;
    }
  }
}

.agents {
  overflow: hidden;

  &.isBottomRoundings {
    &:last-child {
      border-bottom-right-radius: tokens.$radius_xlarge;
      border-bottom-left-radius: tokens.$radius_xlarge;
    }
  }
}

