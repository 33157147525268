@use "ds";
@use "tokens";

.agentsSearchDistrictPrimary {
  padding: 16px;
  border-radius: tokens.$radius_none;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 24px 24px 0;
    border-bottom: 0;
  }

  .icon {
    grid-area: icon;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: none;
    }
  }
}

.content {
  display: grid;
  align-items: center;
  column-gap: 4px;
  row-gap: 8px;
  grid-template-areas:
    "title icon"
    "modal modal";
  grid-template-columns: 1fr 92px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-areas:
      "title images"
      "modal images";
    grid-template-columns: 1fr 311px;
    column-gap: 12px;
    row-gap: 16px;
    align-items: center;
    padding-bottom: 24px;
  }
}

.images {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    grid-area: images;
  }
}

.image {
  display: inline-block;
  border-radius: tokens.$radius_full;
  overflow: hidden;
  border: 2px solid tokens.$color_bg_surface_primary;

  &:not(:first-child) {
    margin-right: -20px;
  }
}

.pin {
  color: tokens.$color_icon_secondary_default;

  &.hasError {
    color: tokens.$color_icon_critical;
  }
}

.title {
  grid-area: title;
}

.modal {
  grid-area: modal;

  .button {
    position: relative;
    text-align: left;
    width: 100%;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      max-width: 357px;
    }

    button {
      justify-content: flex-start;
      color: tokens.$color_icon_secondary_default;
    }

    .badge {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      height: 24px;
    }
  }
}

