@use "ds";
@use "tokens";
@use "typography";

.agentsSearchDistrictModalCheckBoxList {
  display: grid;
  grid-template-columns: 100%;
  gap: 8px;
  align-content: flex-start;
  flex: 1 0 auto;
  padding-top: 24px;

  .mainCheckBox {
    width: fit-content;

    span {
      @include typography.font_label_standard_medium;

      line-height: 22px;
      margin-left: 8px;
      margin-top: 1px;
    }
  }
}

.item {
  padding-bottom: 24px;
  margin-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid tokens.$color_component_divider_default;
  }
}

.mainItem {
  grid-column: 1/ -1;
  display: grid;
  row-gap: 8px;
}

.secondList {
  display: grid;
  grid-template-columns: 100%;
  align-content: flex-start;
  gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-columns: repeat(3, 1fr);
  }
}
