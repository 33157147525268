@use "ds";
@use "tokens";
@use "typography";

.agentsListStickyHeader {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    position: sticky;
    top: 0;
    z-index: 3;
    display: block;
    min-height: 50px;
    width: 100%;
    background-color: tokens.$color_bg_surface_primary;
    padding: 0 24px;

    &:has(+ [class*="container"] > [class*="topAgents"] > div:first-child:hover) {
      .row {
        border-bottom-color: transparent;
        transition: 0.3s ease;
      }
    }
  }

  .row {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: grid;
      column-gap: 12px;
      width: 100%;
      padding: 16px 0;
      border-bottom: 1px solid tokens.$color_border_surface;
      transition: 0.3s ease;
      grid-template-columns: minmax(247px, 358px) minmax(130px, 180px) minmax(52px, 106px) minmax(85px, 106px) 158px;

      &.isSearchDistrict {
        grid-template-columns: minmax(247px, 278px) minmax(130px, 451px) 147px 158px;
      }
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      column-gap: 32px;
      grid-template-columns: 280px minmax(268px, 452px) 50px 90px 158px;

      &.isSearchDistrict {
        grid-template-columns: minmax(247px, 278px) minmax(130px, 451px) 147px 158px;
      }
    }
  }

  .header {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      color: tokens.$color_text_secondary;
      text-align: left;
    }
  }

  .textCenter {
    text-align: center;
  }
}
