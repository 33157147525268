@use "ds";
@use "tokens";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.link {
  a {
    width: 100%;
  }

  span {
    border-bottom: 0;
  }
}

.linkIcon {
  color: tokens.$color_icon_secondary_default;
}
