@use "ds";
@use "tokens";
@use "typography";

.agentsSearchDistrictSecondary {
  display: flex;
  column-gap: 4px;
  align-items: center;

  .district {
    color: tokens.$color_text_info;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_xsmall;

      color: tokens.$color_text_info;
    }
  }

  .removeHandler {
    height: 16px;
    color: tokens.$color_text_info;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      height: 24px;
    }
  }

  .modalHandler {
    height: 20px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      height: 24px;
    }
  }
}
