@use "ds";
@use "tokens";

.agencyReviewsEmptyListForTheYear {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    min-height: 456px;
  }

  .title {
    text-align: center;
  }

  .description {
    color: tokens.$color_text_secondary;
    margin-top: 8px;
  }
}

.buttonContainer {
  width: 100%;
  margin-top: 56px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: none;
  }
}
