.agentsSearchDistrictModal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.emptyListText {
  flex: 1 0 auto;
  margin-top: 32px;
  text-align: center;
}
